// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "bootstrap"
import jquery from "jquery"
import Inputmask from "inputmask";

window.jQuery = jquery;
window.$ = jquery;
window.im = null;

// Currency input masking with jquery-mask
document.addEventListener("turbo:load", (e) => {
    im = new Inputmask({
      alias: 'numeric',
      radixPoint: ',',
      groupSeparator: '.',
      digits: 2,
      autoGroup: true,
      groupSize: 3,
      numericInput: true,
      autoUnmask: true,
      removeMaskOnSubmit: true,
      positionCaretOnClick: "radixFocus",
      onUnMask: function(maskedValue, unmaskedValue) {
        return unmaskedValue.replace(/\./g, '');
      },
      onBeforeMask: function (value, opts) {
        // Divide the value by 100 before masking
        return (parseFloat(value) / 100).toFixed(2);
      }
    })
    window.im.mask(".currency-input");
    Inputmask({"mask": "99.999.999/9999-99"}).mask(".cnpj-input");
    Inputmask({"mask": "99999-999"}).mask(".zip-code-input");
});
