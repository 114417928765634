import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ["submitBtn", "spinner", "btnText", "paymentMessage"];
  static values = { splitId: Number, sessionsUserId: Number, pk: String, rootUrl: String }

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  initialize() {
  	this.stripe = Stripe(
  		this.pkValue,
  		{ "locale": "pt-BR" }
  	);
  	this.initializeStripe();
  }

	// Fetches a payment intent and captures the client secret
	async initializeStripe() {
		let token = document.getElementsByName('csrf-token')[0].content

	  const response = await fetch(`/splits/${this.splitIdValue}/create_payment_intent`, {
	    method: "POST",
	    headers: {
	    	"Content-Type": "application/json",
	    	"X-CSRF-Token": token
	    },
	    body: JSON.stringify({})
	  });

	  const { clientSecret } = await response.json();

	  const appearance = {
	    theme: 'stripe',
			variables: {
	      colorPrimary: "#F57147"
	    }
	  };

	  this.elements = this.stripe.elements({ appearance, clientSecret });

	  const paymentElementOptions = {
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: false,
        spacedAccordionItems: true
      },
	    paymentMethodOrder: ['apple_pay', 'google_pay', 'card']
	  };

	  const paymentElement = this.elements.create("payment", paymentElementOptions);

	  paymentElement.mount("#payment-element");
	}

	async handleSubmit() {
	  this.setLoading(true);
	  let elements = this.elements;

	  const { error } = await this.stripe.confirmPayment({
	    elements,
	    confirmParams: {
	      return_url: `${this.rootUrlValue}sessions_users/${this.sessionsUserIdValue}/splits/${this.splitIdValue}`,
	    },
	  });

	  // If unsuccessful
	  if (error.type === "card_error" || error.type === "validation_error") {
	    this.showMessage(error.message);
	  } else {
	    this.showMessage("An unexpected error occurred.");
	  }

	  this.setLoading(false);
	}

	// ------- UI helpers -------

	showMessage(messageText) {
	  this.paymentMessageTarget.classList.remove("d-none");
	  this.paymentMessageTarget.textContent = messageText;

	  // setTimeout(function () {
	  //   this.paymentMessageTarget.classList.add("d-none");
	  //   messageText.textContent = "";
	  // }, 4000);
	}

	// Show a spinner on payment submission
	setLoading(isLoading) {
	  if (isLoading) {
	    // Disable the button and show a spinner
	    this.submitBtnTarget.disabled = true;
	    this.spinnerTarget.classList.remove("d-none");
	    this.btnTextTarget.classList.add("d-none");
	  } else {
	    this.submitBtnTarget.disabled = false;
	    this.spinnerTarget.classList.add("d-none");
	    this.btnTextTarget.classList.remove("d-none");
	  }
	}
}
