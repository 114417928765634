import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Split Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["payingForPeople", "totalPeople", "equalSplitInput", "bill"];
  static values = { id: Number }

  connect() {
    super.connect();
    this.channel = this.application.consumer.subscriptions.create({
      channel: 'SplitsChannel',
      id: this.idValue
    }, {
      received (data) {
        if (data.cableReady) {
          CableReady.perform(data.operations)
        }
      }
    })
  }

  disconnect() {
    this.channel.unsubscribe()
  }

  updateMinMax() {
    const splitTotalPeople = document.getElementById("split_total_people");
    const splitPayingForPeople = document.getElementById("split_paying_for_people");
    splitTotalPeople.setAttribute("min", splitPayingForPeople.value);
    splitPayingForPeople.setAttribute("max", splitTotalPeople.value);

    for (let target of this.equalSplitInputTargets) {
      target.dispatchEvent(new CustomEvent("updateMinMax", {}));
    }
  }

  updateTip() {
    this.stimulate('Split#update_tip', event.target, { serializeForm: true });
  }

  beforeUpdateTip(element, reflex, noop, reflexId) {
    // console.log(this.element.reflexData[reflexId]);
    // const { params } = this.element.reflexData[reflexId];
    // element.reflexData[reflexId].params[tip_amount] = element.reflexData[reflexId].params[tip_amount].unmaskedvalue();
  }

  afterUpdateTip(element, reflex, noop, reflexId) {
    window.im.mask(".currency-input");
  }
}
