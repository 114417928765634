import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="decrement-increment"
export default class extends Controller {
  static targets = ["input", "decrementButton", "incrementButton"];

  // connect() {
  // }

  connect() {
    this.updateButtons();
  }

  decrement() {
    this.inputTarget.value--;
    this.dispatchChangeEvent();
    this.updateButtons();
  }

  increment() {
    this.inputTarget.value++;
    this.dispatchChangeEvent();
    this.updateButtons();
  }

  updateButtons() {
    this.decrementButtonTarget.disabled = (this.inputTarget.value == this.inputTarget.min);
    this.incrementButtonTarget.disabled = (this.inputTarget.value == this.inputTarget.max);
  }

  dispatchChangeEvent() {
    const changeEvent = new Event('change', { bubbles: true });
    this.inputTarget.dispatchEvent(changeEvent);
  }
}
